import React from "react"
import { Link } from "gatsby"

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {useMouse} from 'react-use';
import {getHeaderStyle, getHeaderImageStyle} from '../utils'

import eyes from '../images/eyes_logo.png'
import EyesInteractive from '../images/svgs/eyes_outside.svg'

export default function Header() {
  const breakpoints = useBreakpoint()

  {/* fetches Mouse position */}
  const ref = React.useRef(null);
  const {docX, docY} = useMouse(ref);

  {/* handles eyes behavior */}
  if (typeof window !== 'undefined') {
    const movement = Math.sin(docX/window.innerWidth + docY/window.innerHeight) * 5;
    const eyesElement = document.getElementById('eyesSVG')
    if (eyesElement !== null) {eyesElement.style.webkitTransform = `rotate(${movement}deg)`}
  }

  return (

    <div ref={ref} style={getHeaderStyle(breakpoints)}>
        <Link to="/">

          {/* static eyes on Mobile (no cursor) */}
          {breakpoints.mobile &&
            <img
              style={getHeaderImageStyle(breakpoints)}
              src={eyes}
              alt={'logo'} />
          }

          {/* interactive eyes on Desktop */}
          {!breakpoints.mobile &&
            <EyesInteractive style={getHeaderImageStyle(breakpoints)}/>
          }
        </Link>
    </div>
  )

}
