/*
################################################################################
GLOBAL COLORS
################################################################################
*/
// List
export const paletteTitle = ['#7EB99C', '#DB88DB', '#AAAA5E']

// List item
export const paletteBg = ['#EDFCF5', '#F8F8FF', '#FCFCEA']
export const paletteBorder = ['#7EB99C', '#DB88DB', '#AAAA5E']

// NavBar
export const paletteNavBar = ['#7EB99C', '#878787', '#DB88DB']


/*
################################################################################
LAYOUTS
################################################################################
*/

// ==============================
// LAYOUTS/HOME.JS
// ==============================

export const getHomeWrapper=(breakpoints) => {
  return {
    paddingBottom: '20vh',
    display: 'flex',
    flexDirection: breakpoints.mobile ? 'column' : 'row',
    justifyContent: 'stretch',
  }
}

export const getSideWrapper=(breakpoints) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  }
}

export const getMainWrapper=(breakpoints) => {
  return {
    margin: '0 auto',
    maxWidth: breakpoints.mobile ? '90%' : '60%',
    alignSelf: 'flex-start',
    overflowX: 'hidden',
  }
}

export const getLatestNewsItem = (breakpoints,sticker) => {
  return {
//    padding: `2rem`,
      marginLeft: '2vw',
    width: '200px',
    height: '200px',
    fontSize: '0.8rem',
    lineHeight: '1.2',
    backgroundImage: `url(${sticker})`,
    backgroundPosition: 'center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    display: breakpoints.mobile ? 'none' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}


// ==============================
// LAYOUTS/PAGE.JS
// ==============================
export const getPageWrapper = getHomeWrapper

export const tocInit = {
    // Where to render the table of contents.
    tocSelector: '.js-toc',
    // Where to grab the headings to build the table of contents.
    contentSelector: 'main',
    // Which headings to grab inside of the contentSelector element.
    headingSelector: 'h1, h2, h3',
    // For headings inside relative or absolute positioned containers within content.
    hasInnerContainers: true,
    // Main class to add to lists.
    linkClass: 'toc-link',
    // Class that gets added when a list should be collapsed.
    isCollapsedClass: 'is-collapsed',
    // Smooth scrolling enabled.
    scrollSmooth: true,
    // Smooth scroll duration.
    scrollSmoothDuration: 420,
    headingsOffset: 40,
    collapseDepth: 0,
}

export const getTocWrapper = (breakpoints) => {
  return {
    position: 'fixed',
    margin: '1.5rem',
    width: '25vw',
//    height: '35vh',
    left: '0vw',
    top: '30vh',
    display: breakpoints.mobile ? 'none' : 'block'
  }
}


/*
################################################################################
COMPONENTS
################################################################################
*/

// ==============================
// COMPONENTS/HEADER
// ==============================

export const getHeaderStyle = (breakpoints) => {
  return {
    padding: `2rem`,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: breakpoints.mobile ? 'center' : 'flex-start'
  }
}

export const getHeaderImageStyle =(breakpoints) => {
  return {
    margin: '0',
    padding: '0',
    width: breakpoints.mobile ? '200px' : '100px',
  }
}

// ==============================
// COMPONENTS/NAVBAR
// ==============================

export const getNavBarWrapper = (breakpoints) => {
  return {
    width: 'auto',
    margin: breakpoints.mobile ? '0 auto' : '1rem 0 1rem 2rem',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: breakpoints.mobile ? 'none' : '1px solid #7EB99C',
    textTransform: 'lowercase',
    paddingBottom: '2vh'
  }
}

export const getMenuItemWrapper = (breakpoints, menu) => {
  const menuItemWrapperDesktop = {
    textDecoration: 'underline',
    paddingLeft: menu.name.split('. ')[1] === 'Standalone Pages' ? '0px' : '10px',
    width: '20vw',
    height: 'auto',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  }
  const menuItemWrapperMobile = {
    textDecoration: 'underline',
    width: 'auto',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    display: menu.name.split('. ')[1] === 'Standalone Pages' ? 'flex' : 'none',
  }
  return breakpoints.mobile ? menuItemWrapperMobile  : menuItemWrapperDesktop
}


// ==============================
// COMPONENTS/LIST/LISTITEMS.JS
// ==============================


export const getListItemWrapper = (num,breakpoints, isHovered) => {
  return {
    listStyle: 'none',
    padding: '2.5vw',
    marginTop: '1vh',
    marginLeft: '0.5vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'space-evenly',
    border: `solid ${paletteBorder[num%paletteBorder.length]}` ,
      borderWidth: '1px 1px 1px 5px',
    background: paletteBg[num%paletteBg.length],
    boxShadow: isHovered
      ? `5px 5px 8px ${paletteBorder[num%paletteBg.length]}`
      : null,
    maxWidth:  breakpoints.mobile ? '100%' : '80%'
    }
}

export const getListItemTitle = (num) => {
  return {
    padding: '0 10px',
    color: paletteBorder[num%paletteBorder.length],
    width: '100%'
  }
}

export const getListItemInnerWrapper = (breakpoints) => {
  return {
    display: 'flex',
    flexDirection: breakpoints.mobile ? 'column' : 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '100%',
  }
}

export const getListItemContent = (breakpoints) => {
  return {
    alignSelf: 'flex-start',
    margin: '0 auto',
    padding: '0 10px',
    width: breakpoints.mobile ? '100%' : '50%',
  }
}

export const getListItemImage = (breakpoints) => {
  return {
    alignSelf: 'center',
    padding: '0 10px',
    margin: '0 auto',
    width: breakpoints.mobile ? '100%' : '50%',
    maxHeight: '250px',
    overflow: 'hidden',
  }
}
// ==============================
// COMPONENTS/LIST/NEWSITEM.JS
// ==============================


export  const getNewsItemWrapper = (breakpoints) => {
  return {
    listStyle: 'none',
    padding: '2rem 0',
    display: 'flex',
    flexDirection: breakpoints.mobile ? 'column' : 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    borderBottom: '1px solid #7EB99C',
    marginBottom: '5vh'
  }
}

export  const getNewsItemTitle = (breakpoints) => {
  return {
    width: breakpoints.mobile ? '100%' : '50%',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  }
}

export  const getNewsItemImage = (breakpoints) => {
  return {
    width:  breakpoints.mobile? '100%' : '40%',
    alignSelf: breakpoints.mobile? 'center' : 'flex-end'
  }
}

export const getNewsItemRSVP = (breakpoints) => {
  return {
    border: '1px solid #7EB99C',
    borderRadius: '25px',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    margin: breakpoints.mobile ? '1vh auto' : '1vh 0'
  }
}
