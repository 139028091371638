import { useStaticQuery, graphql } from "gatsby"
{/*
  React Hook that fetches items from the menus.
  It is used by the NavBar.
*/}

export const useItemsFromToolkitMenu = () => {
    const { allWpMenu } = useStaticQuery(
      graphql`
        query ToolkitData {
          allWpMenu(sort: {fields: name, order: ASC}) {
            nodes {
              name
              menuItems {
                nodes {
                  url
                  title
                  label
                }
              }
            }
          }
        }
      `
    )
    return allWpMenu.nodes
}
