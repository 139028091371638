import React from "react"
import {Link} from "gatsby"
import {useItemsFromToolkitMenu} from "../hooks/useItemsFromToolkitMenu"

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {paletteNavBar, getMenuItemWrapper, getNavBarWrapper} from '../utils'

export default function NavBar() {
  const menuData = useItemsFromToolkitMenu()
  const breakpoints = useBreakpoint()

  return (
    <div
      className='navbar'
      style={getNavBarWrapper(breakpoints)}> {/* responsive behavior */}

        {/* iterates over available Menus */}
        {menuData.map( (menu, menuIndex) => {
          return (
            <div key={menuIndex}>
              {/* skips the "Standalone Pages" title */}
              <Link
                to={'/'}
                style={{
                  display: menu.name.split('. ')[1] === 'Standalone Pages' ? 'none' : 'block',
                  color: paletteNavBar[menuIndex]
                }}>
                {menu.name.split('. ')[1]} {/* removes the number from the name */}
                <span>&#x2197;</span> {/* arrow symbol */}
              </Link>

              <div
                style={getMenuItemWrapper(breakpoints, menu)}>

                {/* iterates over links for given Menu and renders them as Links */}
                {menu && menu.menuItems.nodes.map( (node, index) => (
                  <Link
      						  partiallyActive={true}
                    style={{ color: paletteNavBar[menuIndex] }}
                    key={index}
                    to={node.url}>
                    {node.label} {/* link name */}
                  </Link>
                ))}
              </div>

            </div>
        )})}
    </div>
  )
}
